const filterProducts = (
  allProducts: CommonTypes.ProductDetailsNodesType[],
  filterPack: SolutionsPageTypes.SolutionFilterPackType
): CommonTypes.ProductDetailsNodesType[] => {
  const filteredProducts = allProducts.filter((product) => {
    const insectCondition = filterPack.pest
      ? product.selectInsect.map((insect) => insect.name).includes(filterPack.pest)
      : product;

    const aproachCondition = filterPack.aproach
      ? product.filterSection[0].protectionPicker
          .map((aproach) => aproach.tags)
          .includes(filterPack.aproach)
      : product;

    const formCondition = filterPack.form
      ? product.filterSection[1].protectionPicker.map((form) => form.tags).includes(filterPack.form)
      : product;

    return aproachCondition && insectCondition && formCondition;
  });

  return filteredProducts;
};

export default filterProducts;
