import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import {
  APROACH_FILTER_INDEX,
  APROACH_QUERY_TAG,
  FORM_FILTER_INDEX,
  FORM_QUERY_TAG,
  PEST_FILTER_INDEX,
  PEST_QUERY_TAG,
} from 'shared/constants';

import { DropdownProps, FilterProps } from './models';

import './Filter.scss';

const Filter: FC<FilterProps> = ({
  productCategories,
  setFilterPack,
  queryFilterPack,
  location,
  setActiveFilter,
}) => {
  const [pestFilter, setPestFilter] = useState<DropdownProps | undefined>();
  const [aproachFilter, setAproachFilter] = useState<DropdownProps | undefined>();
  const [formFilter, setFormFilter] = useState<DropdownProps | undefined>();

  const createDropdownValue = (category: CommonTypes.CategoriesType): DropdownProps => ({
    label: category.name,
    value: category.tags,
  });

  const getLabel = (idx: number, value: string | undefined) =>
    productCategories[idx].categoryName.filter((name) => value === name.tags)[0].name;

  const createDropdownFromQuery = (
    value: string | undefined,
    idx: number
  ): DropdownProps | undefined => {
    if (!value) return undefined;

    return {
      label: getLabel(idx, value),
      value,
    };
  };

  const setFilters = () => {
    const queryPestFilter = queryFilterPack?.pest;
    const queryAproachFilter = queryFilterPack?.aproach;
    const queryFormFilter = queryFilterPack?.form;

    setPestFilter(createDropdownFromQuery(queryPestFilter, PEST_FILTER_INDEX));
    setAproachFilter(createDropdownFromQuery(queryAproachFilter, APROACH_FILTER_INDEX));
    setFormFilter(createDropdownFromQuery(queryFormFilter, FORM_FILTER_INDEX));
  };

  useEffect(() => {
    setFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setCleanState = (idx: number) => {
    const cleanState: DropdownProps = {
      label: productCategories[idx].categoryFilterName,
      value: undefined,
    };

    if (idx === PEST_FILTER_INDEX) {
      setPestFilter(cleanState);
    }
    if (idx === APROACH_FILTER_INDEX) {
      setAproachFilter(cleanState);
    }
    if (idx === FORM_FILTER_INDEX) {
      setFormFilter(cleanState);
    }
  };

  useUpdateEffect(() => {
    if (!location) {
      setCleanState(PEST_FILTER_INDEX);
      setCleanState(APROACH_FILTER_INDEX);
      setCleanState(FORM_FILTER_INDEX);
    }
  }, [location]);

  useUpdateEffect(() => {
    const filterPackState: SolutionsPageTypes.SolutionFilterPackType = {
      pest: pestFilter?.value,
      aproach: aproachFilter?.value,
      form: formFilter?.value,
    };

    setFilterPack(filterPackState);
  }, [pestFilter, aproachFilter, formFilter, setFilterPack]);

  const handleChange = (selectedOption: DropdownProps, idx: number) => {
    if (idx === PEST_FILTER_INDEX && pestFilter?.value !== selectedOption.value) {
      setPestFilter(selectedOption);
      setActiveFilter(PEST_QUERY_TAG);
    }

    if (idx === APROACH_FILTER_INDEX && aproachFilter?.value !== selectedOption.value) {
      setAproachFilter(selectedOption);
      setActiveFilter(APROACH_QUERY_TAG);
    }

    if (idx === FORM_FILTER_INDEX && formFilter?.value !== selectedOption.value) {
      setFormFilter(selectedOption);
      setActiveFilter(FORM_QUERY_TAG);
    }
  };

  const getActiveFilter = (idx: number) => {
    if (idx === PEST_FILTER_INDEX && pestFilter)
      return {
        label: pestFilter ? productCategories[PEST_FILTER_INDEX].categoryFilterName : undefined,
        value: pestFilter?.value,
      };
    if (idx === APROACH_FILTER_INDEX && aproachFilter)
      return {
        label: aproachFilter
          ? productCategories[APROACH_FILTER_INDEX].categoryFilterName
          : undefined,
        value: aproachFilter?.value,
      };
    if (idx === FORM_FILTER_INDEX && formFilter)
      return {
        label: formFilter ? productCategories[FORM_FILTER_INDEX].categoryFilterName : undefined,
        value: formFilter?.value,
      };

    return undefined;
  };

  const renderDropdowns = productCategories.map(
    (filter: CommonTypes.ProductCategoriesType, filterIdx: number) => {
      const options: DropdownProps[] | undefined = filter.categoryName.map(
        (category: CommonTypes.CategoriesType, categoryIdx: number) => {
          const newCategory = category;

          if (categoryIdx === 0) newCategory.tags = undefined;

          return createDropdownValue(newCategory);
        }
      );

      return (
        <Select
          key={filter.categoryFilterName}
          options={options}
          placeholder={filter.categoryFilterName}
          className="filter__select"
          classNamePrefix="filter"
          onChange={(option) => option && handleChange(option, filterIdx)}
          value={getActiveFilter(filterIdx)}
          aria-label={filter.categoryFilterName}
          openMenuOnFocus
          isSearchable={false}
        />
      );
    }
  );

  return <div className="filter">{renderDropdowns}</div>;
};
export default Filter;
