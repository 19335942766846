import React, { FC, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import ProductListing from 'containers/ProductListing';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { isEqual, uniqBy } from 'lodash';
import {
  APROACH_FILTER_INDEX,
  APROACH_QUERY_TAG,
  FORM_FILTER_INDEX,
  FORM_QUERY_TAG,
  PEST_FILTER_INDEX,
  PEST_QUERY_TAG,
  QUERY_CONNECTOR,
} from 'shared/constants';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import { BANNER } from 'components/Banner/models.d';
import Filter from 'components/Filter';
import { isBrowser } from 'utils/browser';
import filterProducts from 'utils/filterProducts';

import './SolutionPage.scss';

const SolutionsPage: FC<{ data: SolutionsPageTypes.SolutionsPageSiteTypes }> = ({
  data: {
    solutions: { urls, seo, banner, productCategories, filtersTitle, pageName },
    allProductDetails: { nodes: productCards },
  },
}) => {
  const initialFilterPack: SolutionsPageTypes.SolutionFilterPackType = {
    pest: undefined,
    aproach: undefined,
    form: undefined,
  };

  const { title, keywords, description } = seo;

  const location = isBrowser() ? window.location.search : undefined;
  const solutionPath = isBrowser() ? window.location.pathname : undefined;

  const queryString = useMemo(() => new URLSearchParams(location), [location]);

  const [filterPack, setFilterPack] =
    useState<SolutionsPageTypes.SolutionFilterPackType>(initialFilterPack);

  const [filteredProducts, setFilteredProducts] =
    useState<CommonTypes.ProductDetailsNodesType[]>(productCards);

  const [activeFilter, setActiveFilter] = useState();

  const [previousFormFilter, setPreviousFormFilter] = useState<CommonTypes.CategoriesType[]>();
  const [previousAproachFilter, setPreviousAproachFilter] =
    useState<CommonTypes.CategoriesType[]>();
  const [previousPestFilter, setPreviousPestFilter] = useState<CommonTypes.CategoriesType[]>();

  const collectQueryFilterPack = (query: URLSearchParams) => {
    const pestQuery = query.get(PEST_QUERY_TAG);
    const aproachQuery = query.get(APROACH_QUERY_TAG);
    const formQuery = query.get(FORM_QUERY_TAG);
    const queryFilterPack = {
      pest: pestQuery || undefined,
      aproach: aproachQuery || undefined,
      form: formQuery || undefined,
    };

    return queryFilterPack;
  };

  const getQueryFilters = () => {
    const pestQuery = queryString.get(PEST_QUERY_TAG);
    const aproachQuery = queryString.get(APROACH_QUERY_TAG);
    const formQuery = queryString.get(FORM_QUERY_TAG);

    return { pestQuery, aproachQuery, formQuery };
  };

  const allSolutions = [productCategories[FORM_FILTER_INDEX].categoryName[0]].concat(
    filteredProducts.map((product) => product.filterSection[1].protectionPicker).flat()
  );

  const allAproaches = [productCategories[APROACH_FILTER_INDEX].categoryName[0]].concat(
    filteredProducts.map((product) => product.filterSection[0].protectionPicker).flat()
  );

  const allInsects = [productCategories[PEST_FILTER_INDEX].categoryName[0]].concat(
    filteredProducts.map((product) => product.selectInsect).flat()
  );

  const availableAproaches = uniqBy(allAproaches, 'id');
  const availableSolutions = uniqBy(allSolutions, 'id');
  const availableInsects = uniqBy(allInsects, 'id');

  useEffect(() => {
    const { pestQuery, aproachQuery, formQuery } = getQueryFilters();

    const controlFilterPack: SolutionsPageTypes.SolutionFilterPackType = {
      pest: pestQuery || undefined,
      aproach: aproachQuery || undefined,
      form: formQuery || undefined,
    };

    const isInitial = isEqual(initialFilterPack, controlFilterPack);

    if (!isInitial) setFilterPack(controlFilterPack);
    if (!previousFormFilter) setPreviousFormFilter(availableSolutions);
    if (!previousAproachFilter) setPreviousAproachFilter(availableAproaches);
    if (!previousPestFilter) setPreviousPestFilter(availableInsects);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    const pestQueryString = filterPack.pest && `${PEST_QUERY_TAG}=${filterPack.pest}`;
    const aproachQueryString = filterPack.aproach && `${APROACH_QUERY_TAG}=${filterPack.aproach}`;
    const formQueryString = filterPack.form && `${FORM_QUERY_TAG}=${filterPack.form}`;

    const filteredQueryStrings = [pestQueryString, aproachQueryString, formQueryString].filter(
      (string) => string
    );

    const newQuery = new URLSearchParams(filteredQueryStrings.join(QUERY_CONNECTOR));

    const newUrl = `${solutionPath}?${newQuery.toString()}`;

    const replaceHistory = (url: string | undefined) =>
      isBrowser() && url && window.history.replaceState({}, '', url);

    if (newQuery.toString()) {
      replaceHistory(newUrl);
    } else {
      setPreviousFormFilter(availableSolutions);
      setPreviousAproachFilter(availableAproaches);
      setPreviousPestFilter(availableInsects);
      replaceHistory(solutionPath);
    }
  }, [filterPack]); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    const filtered = filterProducts(productCards, filterPack);
    setFilteredProducts(filtered);
  }, [filterPack, productCards]);

  useUpdateEffect(() => {
    if (activeFilter !== FORM_QUERY_TAG) {
      setPreviousFormFilter(availableSolutions);
    }

    if (activeFilter !== APROACH_QUERY_TAG) {
      setPreviousAproachFilter(availableAproaches);
    }

    if (activeFilter !== PEST_QUERY_TAG) {
      setPreviousPestFilter(availableInsects);
    }
  }, [filteredProducts]);

  const setForm = (
    queryTag: string,
    previousFilter: CommonTypes.CategoriesType[] | undefined,
    availableFilter: CommonTypes.CategoriesType[],
    filterPackType: string | undefined
  ) => {
    if (activeFilter === queryTag && previousFilter) {
      if (filterPackType) {
        return previousFilter;
      }

      return availableFilter;
    }

    return availableFilter;
  };

  const filteredCategories = productCategories.map(
    (category: CommonTypes.ProductCategoriesType, idx: number) => {
      const newCategory = category;

      if (idx === PEST_FILTER_INDEX)
        newCategory.categoryName = setForm(
          PEST_QUERY_TAG,
          previousPestFilter,
          availableInsects,
          filterPack.pest
        );
      if (idx === APROACH_FILTER_INDEX)
        newCategory.categoryName = setForm(
          APROACH_QUERY_TAG,
          previousAproachFilter,
          availableAproaches,
          filterPack.aproach
        );
      if (idx === FORM_FILTER_INDEX)
        newCategory.categoryName = setForm(
          FORM_QUERY_TAG,
          previousFormFilter,
          availableSolutions,
          filterPack.form
        );

      return newCategory;
    }
  );

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner banner={banner} variant={BANNER.VARIANT.SECONDARY} />
      <Container fluid element="section" className="filter-section">
        <Typography variant="heading2" align="center" weight="medium">
          {filtersTitle}
        </Typography>
        <Filter
          productCategories={filteredCategories}
          setFilterPack={setFilterPack}
          queryFilterPack={collectQueryFilterPack(queryString)}
          location={location}
          setActiveFilter={setActiveFilter}
        />
      </Container>
      <ProductListing productCards={filteredProducts} />
    </Layout>
  );
};

export const query = graphql`
  query SolutionsPageQuery($lang: String) {
    solutions(lang: { eq: $lang }) {
      ...SolutionsPageFragment
    }
    allProductDetails {
      nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default SolutionsPage;
