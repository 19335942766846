import React, { FC, useState } from 'react';
import { useScreenService } from 'hooks/useScreenService';
import { NUMBER } from 'shared/constants/numbers';

import { Container } from 'layout';
import Pagination from 'components/Pagination';
import ProductCard from 'components/ProductCard';

import { ProductListingProps } from './models';

import './ProductListing.scss';

const ProductListing: FC<ProductListingProps> = ({ productCards }) => {
  const [currentPage, setCurrentPage] = useState<number>(NUMBER.FIRST_PAGE);

  const { isMobile } = useScreenService();

  const productsPerPage = isMobile
    ? NUMBER.PRODUCTS_PER_MOBILE_PAGE
    : NUMBER.PRODUCTS_PER_DESKTOP_PAGE;

  if (!productCards) return null;

  const productList =
    currentPage === NUMBER.FIRST_PAGE
      ? productCards?.slice(0, productsPerPage)
      : productCards?.slice(productsPerPage * (currentPage - 1), productsPerPage * currentPage);
  if (currentPage !== NUMBER.FIRST_PAGE && !productList.length) {
    setCurrentPage(NUMBER.FIRST_PAGE);
  }

  const renderProducts = productList?.map(
    ({
      productName,
      productText,
      image: { image },
      filterSection,
      button,
    }: CommonTypes.ProductCardType) => (
      <li key={productName} className="product-listing__item">
        <ProductCard
          image={image}
          title={productName}
          description={productText}
          filterSection={filterSection}
          button={button}
        />
      </li>
    )
  );

  return (
    <Container element="section" className="product-listing">
      <ul className="product-listing__list">{renderProducts}</ul>
      <Pagination
        total={productCards?.length}
        itemsPerPage={productsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        customClass="product-listing__pagination"
      />
    </Container>
  );
};

export default ProductListing;
